.models {
    margin-left: 4.5rem;
    margin-top: 15px;
    overflow-y: scroll;
}

#upload-file-form .fileBrowseContainer {
    display: flex;
}

#upload-file-form .fileBrowseContainer button {
    /* following are to override IconButton strange behavior */
    outline: none;
    border-color: transparent;
    box-shadow: none;
}

#upload-file-form .stretch {
    width: -webkit-fill-available;
}

#upload-file-form .browseButton {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
}

#upload-file-form .browseButton label {
    margin: 0;
    height: 36px; /* to match inner content (the button) exactly */
}

#modelFileInput {
    display: none;
}

#upload-file-form .buttonsContainer {
    display: flex;
    flex-flow: row-reverse;
    padding-bottom: 0;
    padding-top: 8px;
    padding-right: 8px;
}

#upload-file-form textarea {
    height: 100px !important;
}

#upload-file-form .buttonsContainer button {
    width: 102px;
}

.upload-fail-message {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
    padding-right: 36px !important;
}

#upload-file-form .creation-failed-message {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
    margin-bottom: 10px;
}

.checkboxfield {
    position: relative;
}

.stretch .checkboxtext {
    position: absolute;
    top: 0;
    left: 25px;
    font-size: 14px;
    font-family: ArtifaktElement,sans-serif;
}

.processing-ring-percentage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 35px;
    height: 47%;
}