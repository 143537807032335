.igu-dialog {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    display: flex;
    flex-direction: column; 
    height: 100%;
    text-rendering: geometricPrecision;
    font-size: 14px;
    box-sizing: content-box;
}

.igu-item {
    margin-bottom: 10px;
    padding-bottom: 15px;
    cursor: pointer;
}

.selected-igu-item {
    background-color: rgba(100, 149, 237, 0.1);
}

.igu-item-operations {
    padding-left: 20px;
    margin: 0;
}

.igu-valid {
    list-style: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22green%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E');
    height: 23px;
}

.igu-not-valid {
    list-style: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22red%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2210%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%224.93%22%20y1%3D%224.93%22%20x2%3D%2219.07%22%20y2%3D%2219.07%22%3E%3C%2Fline%3E%3C%2Fsvg%3E');
    height: 23px;
}

.igu-operation-header {
    list-style-type: none;
    height: 23px;
}

.igu-operation-container {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    height: 100%;
}

.igu-cell {
    border: dotted;
    border-width: thin;
}

.igu-operation-title-cell {
    width: 90px;
}

.igu-operation-value-cell {
    width: 42px;
    text-align: right;
    padding-right: 2px;
}

.igu-price-container {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
    padding-right: 20px;
}