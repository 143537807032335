html,
body {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-flow: column;
}

.fullheight {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.centrified {
  margin: auto
}

.anonymous-pane {
  display: flex;
  flex-direction: row !important;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.projectCard {
  transition: background-color 250ms;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.scrollBar::-webkit-scrollbar {
  display: none;
}

.linear-activity {
  overflow: hidden;
  width: 50%;
  height: 6px;
  background-color: #b3e5fc;
  margin: 20px auto;
  border-radius: 3px;
}

.determinate {
  position: relative;
  max-width: 100%;
  height: 100%;
  -webkit-transition: width 500ms ease-out 1s;
  -moz-transition: width 500ms ease-out 1s;
  -o-transition: width 500ms ease-out 1s;
  transition: width 500ms ease-out 1s;
  background-color: #03a9f4;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #03a9f4;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #4fc3f7;
  animation: indeterminate_second 1.5s infinite ease-in;
}

.react-calendar {
  border-radius: 12px;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 31px;
  width: 320px;
}

.copyright {
  position: absolute;
  bottom: 5px;
  right: 25px;
  font-size: 12px;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently not supported by any browser */
}

.two-lines-textbox {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

:focus-visible {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #80808032;
  /* color:#808080 32 opacity 20%*/
}

/* whole page scroll bars */
::-webkit-scrollbar-thumb:hover {
  background-color: #80808080;
  /* color:#808080 80 opacity 50%*/
}

.optionSwitcherCard:hover {
  background-color: #f7f7f7;
}