.title {
    padding: 10px 0;
}

.table-container {
    display: flex;
    flex-direction: column;
}

.flex-table {
    display: grid;
    grid-template-columns: repeat(3, 260px) 200px repeat(2, 140px);
    grid-template-rows: 100% auto;
    border-left: solid 1px #d9d9d9;
    transition: 0.5s;
    margin: 0 auto;
}

.header, .footer {
    flex: 0;
    margin: 0;
}

.footer {
    display: flex;
    position: fixed;
    bottom: 5px;
    width: 1262px;
    background: #dedede;
    border-radius: 5px;
}

.date-table {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
}

.flex-table-main {
    display: grid;
    grid-template-columns: repeat(2, 230px) 390px repeat(2, 140px);
    grid-template-rows: 100% auto;
    border-left: solid 1px #d9d9d9;
    transition: 0.5s;
}

.scroll {
    flex-grow: 1;

    overflow: hidden scroll;

    /* for Firefox */
    min-height: 0;
}

.flex-row {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
    border-right: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;
}

.flex-row-head {
    background: #cccccc;
}

.flex-row-head:not(:last-of-type) {
    border-right: solid 1px #fff !important;
}

.rowspan {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 100%;
}

.column {
    width: 100%;
    padding: 0;
}

.column .flex-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, 33.3%);
    grid-template-rows: 100% 100% 100%;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px #d9d9d9;
}

.flex-cell {
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9;
}

.price-item-name-column-value {
    padding-left: 0.5em;
}

.price-item-column-value {
    padding-right: 0.5em;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.table__info {
    display: grid;
    grid-template-columns: repeat(2, 600px);
    justify-content: center;
}

.table__item {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.table__item_header {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
    min-height: 30px;
}
.table__item_element {
    max-width: 250px;
    min-height: 24px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table__item_title {
    font-weight: 600;
}
