.model-editor-viewer-modals-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.model-editor-viewer-modal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 500;
}