.maintenance-backdrop-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff5;
    z-index: 50000;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.maintenance-image {
    border-radius: 15px;
}

.maintenance-title {
    font-family: 'Roboto';
    font-size: 20px;
}

.scheduled-maintenance-banner-containter {
    position: absolute;
    left: calc(50% - 100px);
    top: 15px;
    width: 500px;
}