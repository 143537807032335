.processing-pane header button {
    display: none;
}

.processingProgressBar {
    margin-top: 24px;
}

.processingProgressImage {
    width: 48px;
    height: 48px;
}

.processingProgressPane {
    margin: auto;
    position: relative;
}

.processingProgressTimePrompt {
    text-align: right;
    font-size: 10px;
    position: absolute;
    right: 0;
    top: 17px;
    font-family: ArtifaktElement,sans-serif;
}

.processingProgressStatusPane {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 13px;
    font-family: ArtifaktElement,sans-serif;
}

.processingProgressContent {
    display: flex;
    padding: 12px 0 24px 0;
}

.processingProgressAction {
    padding-left: 24px;
    width: 275px;
    padding-top: 8px;
    padding-bottom: 8px;
}